<template>
    <zw-sidebar @shown="shown" titleClass="text-center m-auto"
                :title="form.title"
                :callbackClose="setShown"
    >
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">
            <template>
                <b-row class="p-2">
                    <b-col cols="4">
                        <div v-if="form.image">
                            <img class="w-100" :src="'data:'+form.image.type+';base64,'+form.image.content" alt="">
                        </div>
                    </b-col>
                    <b-col cols="8">
                        <div v-html="form.description"></div>
                    </b-col>
                </b-row>
                <b-row class="mt-4 text-center">
                    <b-col cols="6">
                        <a :href="form.link" target="_blank" class="btn btn-primary" role="button" v-if="form.link"
                        >{{ $t('common.button.read_more') }}</a>
                    </b-col>
                    <b-col cols="6">
                        <a :href="form.linkTree" target="_blank" class="btn btn-info" role="button"
                        >{{ $t('common.button.contact_us') }}</a>
                    </b-col>
                </b-row>
            </template>
        </div>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import axios from "axios";

export default {
    name: 'show-news-modal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            form: {},
            labelPrefix: 'common.label.news.',
            response: null,
        }
    },
    mounted() {
    },
    computed: {},
    methods: {
        ...mapGetters('News', ['getThenew']),
        ...mapGetters('CommonData', ['getCommonData']),
        shown() {
            this.form = this.payload.news
            this.loading = false
        },
        setShown() {
            let commonData = this.getCommonData();
            let storageName = 'lastShowNews_' + commonData.domain + '_' + commonData.user.id;
            var today = new Date().setHours(0, 0, 0, 0)
            axios.get(window.apiUrl + '/news/set-shown/' + this.form.id + '/' + commonData.domain + '/' + commonData.user.id)
                .then(response => {
                    localStorage.setItem(storageName, today);
                })
        },
    }
}
</script>